// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "ForSalePropertyV5",
              "RentalPropertyV5"
            ]);
}

var fragmentConverter = {"__root":{"RentalPropertyV5":{"f":""},"ForSalePropertyV5":{"f":""},"":{"u":"fragment"}}};

var fragmentConverterMap = {
  fragment: unwrap_fragment
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithdrawnSharedPropertyV5_propertyAttributes",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ForSalePropertyAttributes_property"
        }
      ],
      "type": "ForSalePropertyV5",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RentalPropertyAttributes_property"
        }
      ],
      "type": "RentalPropertyV5",
      "abstractKey": null
    }
  ],
  "type": "PropertyV5",
  "abstractKey": "__isPropertyV5"
};

var wrap_fragment = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment = unwrap_fragment;
exports.wrap_fragment = wrap_fragment;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
