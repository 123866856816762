// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var MarkupHelpers = require("../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var VerifiedAgentCardV5 = require("./VerifiedAgentCardV5.bs.js");
var SharedRentalProperty = require("./SharedRentalProperty.bs.js");
var SharedForSaleProperty = require("./SharedForSaleProperty.bs.js");
var SharedPropertyMessage = require("./SharedPropertyMessage.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var WithdrawnSharedPropertyV5 = require("./WithdrawnSharedPropertyV5.bs.js");
var UnselectedUnionMemberError = require("../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var RelaySharedProperty_sharedProperty_graphql = require("../../__generated__/RelaySharedProperty_sharedProperty_graphql.bs.js");

var convertFragment = RelaySharedProperty_sharedProperty_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, RelaySharedProperty_sharedProperty_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(RelaySharedProperty_sharedProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, RelaySharedProperty_sharedProperty_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(RelaySharedProperty_sharedProperty_graphql.node, convertFragment, fRef);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function RelaySharedProperty(props) {
  var sharedProperty = use(props.sharedProperty);
  var p = sharedProperty.property;
  var tmp;
  switch (p.__typename) {
    case "ForSalePropertyV5" :
        tmp = p.status.__typename === "WithdrawnProperty" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(WithdrawnSharedPropertyV5.make, {
                        property: p.fragmentRefs,
                        sharedProperty: sharedProperty.fragmentRefs
                      }),
                  JsxRuntime.jsx(MarkupHelpers.Container.make, {
                        children: JsxRuntime.jsx(VerifiedAgentCardV5.FromSharedProperty.make, {
                              sharedProperty: sharedProperty.fragmentRefs
                            })
                      })
                ]
              }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(SharedPropertyMessage.make, {
                        sharedProperty: sharedProperty.fragmentRefs
                      }),
                  JsxRuntime.jsx(SharedForSaleProperty.make, {
                        property: p.fragmentRefs,
                        sharedProperty: sharedProperty.fragmentRefs
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(MarkupHelpers.Container.make, {
                              className: "py-4",
                              children: JsxRuntime.jsx(VerifiedAgentCardV5.FromSharedProperty.make, {
                                    sharedProperty: sharedProperty.fragmentRefs
                                  })
                            }),
                        className: "bg-gray-100"
                      })
                ]
              });
        break;
    case "RentalPropertyV5" :
        tmp = p.status.__typename === "WithdrawnProperty" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(WithdrawnSharedPropertyV5.make, {
                        property: p.fragmentRefs,
                        sharedProperty: sharedProperty.fragmentRefs
                      }),
                  JsxRuntime.jsx(MarkupHelpers.Container.make, {
                        children: JsxRuntime.jsx(VerifiedAgentCardV5.FromSharedProperty.make, {
                              sharedProperty: sharedProperty.fragmentRefs
                            })
                      })
                ]
              }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(SharedPropertyMessage.make, {
                        sharedProperty: sharedProperty.fragmentRefs
                      }),
                  JsxRuntime.jsx(SharedRentalProperty.make, {
                        property: p.fragmentRefs,
                        sharedProperty: sharedProperty.fragmentRefs
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(MarkupHelpers.Container.make, {
                              className: "py-4",
                              children: JsxRuntime.jsx(VerifiedAgentCardV5.FromSharedProperty.make, {
                                    sharedProperty: sharedProperty.fragmentRefs
                                  })
                            }),
                        className: "bg-gray-100"
                      })
                ]
              });
        break;
    case "__unselected" :
        tmp = UnselectedUnionMemberError.raise("Unknown property type " + p._0);
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: "pt-8 flex flex-col gap-12"
            });
}

var make = RelaySharedProperty;

exports.Fragment = Fragment;
exports.make = make;
/* MarkupHelpers Not a pure module */
