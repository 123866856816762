// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment_property_ForSalePropertyV5_status(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["WithdrawnProperty"]);
}

function unwrap_fragment_property_RentalPropertyV5_status(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["WithdrawnProperty"]);
}

function unwrap_fragment_property(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "ForSalePropertyV5",
              "RentalPropertyV5"
            ]);
}

var fragmentConverter = {"__root":{"property_RentalPropertyV5_status":{"u":"fragment_property_RentalPropertyV5_status"},"property_RentalPropertyV5":{"f":""},"property_ForSalePropertyV5_status":{"u":"fragment_property_ForSalePropertyV5_status"},"property_ForSalePropertyV5":{"f":""},"property":{"u":"fragment_property"},"":{"f":""}}};

var fragmentConverterMap = {
  fragment_property_ForSalePropertyV5_status: unwrap_fragment_property_ForSalePropertyV5_status,
  fragment_property_RentalPropertyV5_status: unwrap_fragment_property_RentalPropertyV5_status,
  fragment_property: unwrap_fragment_property
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "WithdrawnSharedPropertyV5_property"
},
v2 = {
  "kind": "RequiredField",
  "field": {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "status",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v0/*: any*/)
        ],
        "type": "WithdrawnProperty",
        "abstractKey": null
      }
    ],
    "storageKey": null
  },
  "action": "THROW"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RelaySharedProperty_sharedProperty",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedPropertyMessage_sharedProperty"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedForSaleProperty_sharedProperty"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VerifiedAgentCardV5_sharedProperty"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRentalProperty_sharedProperty"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WithdrawnSharedPropertyV5_sharedProperty"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SharedForSaleProperty_property"
              },
              (v2/*: any*/)
            ],
            "type": "ForSalePropertyV5",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SharedRentalProperty_property"
              }
            ],
            "type": "RentalPropertyV5",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "SharedPropertyV5",
  "abstractKey": null
};
})());

var wrap_fragment_property_ForSalePropertyV5_status = RescriptRelay_Internal.wrapUnion;

var wrap_fragment_property_RentalPropertyV5_status = RescriptRelay_Internal.wrapUnion;

var wrap_fragment_property = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment_property_ForSalePropertyV5_status = unwrap_fragment_property_ForSalePropertyV5_status;
exports.wrap_fragment_property_ForSalePropertyV5_status = wrap_fragment_property_ForSalePropertyV5_status;
exports.unwrap_fragment_property_RentalPropertyV5_status = unwrap_fragment_property_RentalPropertyV5_status;
exports.wrap_fragment_property_RentalPropertyV5_status = wrap_fragment_property_RentalPropertyV5_status;
exports.unwrap_fragment_property = unwrap_fragment_property;
exports.wrap_fragment_property = wrap_fragment_property;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
