// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Next__Image = require("../../reason/next/Next__Image.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var MarkupHelpers = require("../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var PropertyPrice = require("../common/Property/PropertyPrice.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../reason/common/Theme/Theme__Color__Vars.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RentalPropertyAttributes = require("../common/Property/RentalPropertyAttributes.bs.js");
var ForSalePropertyAttributes = require("../common/Property/ForSalePropertyAttributes.bs.js");
var UnselectedUnionMemberError = require("../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var WithdrawnSharedPropertyLocation = require("./WithdrawnSharedPropertyLocation.bs.js");
var WithdrawnSharedPropertyV5_property_graphql = require("../../__generated__/WithdrawnSharedPropertyV5_property_graphql.bs.js");
var WithdrawnSharedPropertyV5_sharedProperty_graphql = require("../../__generated__/WithdrawnSharedPropertyV5_sharedProperty_graphql.bs.js");
var WithdrawnSharedPropertyV5_propertyAttributes_graphql = require("../../__generated__/WithdrawnSharedPropertyV5_propertyAttributes_graphql.bs.js");

var convertFragment = WithdrawnSharedPropertyV5_propertyAttributes_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, WithdrawnSharedPropertyV5_propertyAttributes_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(WithdrawnSharedPropertyV5_propertyAttributes_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, WithdrawnSharedPropertyV5_propertyAttributes_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(WithdrawnSharedPropertyV5_propertyAttributes_graphql.node, convertFragment, fRef);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function WithdrawnSharedPropertyV5$PropertyAttributes(props) {
  var property = use(props.property);
  var tmp;
  switch (property.__typename) {
    case "ForSalePropertyV5" :
        tmp = JsxRuntime.jsx(ForSalePropertyAttributes.make, {
              property: property.fragmentRefs
            });
        break;
    case "RentalPropertyV5" :
        tmp = JsxRuntime.jsx(RentalPropertyAttributes.make, {
              property: property.fragmentRefs
            });
        break;
    case "__unselected" :
        tmp = UnselectedUnionMemberError.raise("Unexpected unselected union member: " + property._0);
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: "flex flex-col gap-2"
            });
}

var PropertyAttributes = {
  Fragment: Fragment,
  make: WithdrawnSharedPropertyV5$PropertyAttributes
};

var convertFragment$1 = WithdrawnSharedPropertyV5_property_graphql.Internal.convertFragment;

function waitForFragmentData$1(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, WithdrawnSharedPropertyV5_property_graphql.node, convertFragment$1, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(WithdrawnSharedPropertyV5_property_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, WithdrawnSharedPropertyV5_property_graphql.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(WithdrawnSharedPropertyV5_property_graphql.node, convertFragment$1, fRef);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  waitForFragmentData: waitForFragmentData$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

var convertFragment$2 = WithdrawnSharedPropertyV5_sharedProperty_graphql.Internal.convertFragment;

function waitForFragmentData$2(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, WithdrawnSharedPropertyV5_sharedProperty_graphql.node, convertFragment$2, fRef);
}

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(WithdrawnSharedPropertyV5_sharedProperty_graphql.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, WithdrawnSharedPropertyV5_sharedProperty_graphql.node, convertFragment$2);
}

function readResolverFragment$2(fRef) {
  return RescriptRelay_Fragment.read(WithdrawnSharedPropertyV5_sharedProperty_graphql.node, convertFragment$2, fRef);
}

var SharedPropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  waitForFragmentData: waitForFragmentData$2,
  use: use$2,
  useOpt: useOpt$2,
  readResolverFragment: readResolverFragment$2
};

function WithdrawnSharedPropertyV5$SlimCard(props) {
  var property = use$1(props.property);
  var sharedProperty = use$2(props.sharedProperty);
  var imageSrc = Core__Option.flatMap(Core__Option.flatMap(Core__Option.map(property.firstImage, (function (images) {
                  return images.edges;
                })), (function (edges) {
              return edges[0];
            })), (function (edge) {
          return Core__Option.map(edge, (function (e) {
                        return e.node.url;
                      }));
        }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: imageSrc !== undefined ? JsxRuntime.jsx(Next__Image.make, {
                              src: imageSrc,
                              fill: true,
                              unoptimized: true,
                              alt: "",
                              className: "object-cover"
                            }) : JsxRuntime.jsx(Icon__Jsx3.make, {
                              icon: "Home",
                              size: 42
                            }),
                      className: "relative flex-shrink-0 w-full h-32 bg-gray-200 flex items-center justify-center md:w-44 md:h-auto"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(WithdrawnSharedPropertyLocation.make, {
                              sharedProperty: sharedProperty.fragmentRefs
                            }),
                        JsxRuntime.jsx(PropertyPrice.make, {
                              property: property.fragmentRefs,
                              size: "medium"
                            }),
                        JsxRuntime.jsx(WithdrawnSharedPropertyV5$PropertyAttributes, {
                              property: property.fragmentRefs
                            })
                      ],
                      className: "flex-grow flex flex-col p-4 gap-2"
                    })
              ],
              className: "w-full flex flex-col md:flex-row border rounded border-gray-200 bg-white"
            });
}

var SlimCard = {
  make: WithdrawnSharedPropertyV5$SlimCard
};

function WithdrawnSharedPropertyV5(props) {
  return JsxRuntime.jsx(MarkupHelpers.Container.make, {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Icon__Jsx3.make, {
                                        icon: "AttentionAlt",
                                        className: "text-biscay h-15 w-15",
                                        size: 60,
                                        color: Theme__Color__Vars.biscay
                                      }),
                                  className: "pl-4 pr-8"
                                }),
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: "This property is no longer available",
                                                  className: "text-3xl text-gray-700"
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: "This property has been withdrawn from TAN. For more information, please contact your agent.",
                                                  className: "mt-2 text-base"
                                                })
                                          ],
                                          className: "mb-6"
                                        }),
                                    JsxRuntime.jsx(WithdrawnSharedPropertyV5$SlimCard, {
                                          property: props.property,
                                          sharedProperty: props.sharedProperty
                                        })
                                  ]
                                })
                          ],
                          className: "flex flex-row"
                        }),
                    className: "bg-white p-5 border rounded"
                  })
            });
}

var make = WithdrawnSharedPropertyV5;

exports.PropertyAttributes = PropertyAttributes;
exports.PropertyFragment = PropertyFragment;
exports.SharedPropertyFragment = SharedPropertyFragment;
exports.SlimCard = SlimCard;
exports.make = make;
/* Icon__Jsx3 Not a pure module */
