// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Scalars = require("../../scalars/Scalars.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AvatarSrcUrl = require("../../utils/AvatarSrcUrl.bs.js");
var Avatar__Jsx3 = require("../../uikit/reason/atoms/Avatar/Avatar__Jsx3.bs.js");
var Buttons__Jsx3 = require("../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var SharedPropertySidecard_agent_graphql = require("../../__generated__/SharedPropertySidecard_agent_graphql.bs.js");

var convertFragment = SharedPropertySidecard_agent_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, SharedPropertySidecard_agent_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SharedPropertySidecard_agent_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SharedPropertySidecard_agent_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(SharedPropertySidecard_agent_graphql.node, convertFragment, fRef);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function SharedPropertySidecard(props) {
  var sender = use(props.agent);
  var profilePhoto = sender.profilePhoto;
  var displayName = sender.displayName;
  var avatar = profilePhoto !== undefined ? profilePhoto.sidecardUrl : AvatarSrcUrl.place_holder;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Contact your agent to learn more about this property",
                      className: "text-center text-gray-700 font-medium text-lg"
                    }),
                JsxRuntime.jsx(Avatar__Jsx3.make, {
                      src: avatar,
                      name: "Avatar for " + displayName,
                      size: "medium"
                    }),
                JsxRuntime.jsx("a", {
                      children: JsxRuntime.jsx(Buttons__Jsx3.PrimaryButton.make, {
                            children: Caml_option.some(JsxRuntime.jsx("div", {
                                      children: "Contact " + displayName
                                    }))
                          }),
                      href: "mailto:" + Scalars.EmailAddress.toString(sender.emailAddress)
                    })
              ],
              className: "border border-gray-200 rounded-md bg-white  sticky top-16 max-w-sm\n  flex flex-col items-center p-5 gap-4\n  "
            });
}

var make = SharedPropertySidecard;

exports.Fragment = Fragment;
exports.make = make;
/* Scalars Not a pure module */
