// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../common/ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var WithdrawnSharedPropertyLocation_sharedProperty_graphql = require("../../__generated__/WithdrawnSharedPropertyLocation_sharedProperty_graphql.bs.js");

var convertFragment = WithdrawnSharedPropertyLocation_sharedProperty_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, WithdrawnSharedPropertyLocation_sharedProperty_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(WithdrawnSharedPropertyLocation_sharedProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, WithdrawnSharedPropertyLocation_sharedProperty_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(WithdrawnSharedPropertyLocation_sharedProperty_graphql.node, convertFragment, fRef);
}

var SharedPropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function WithdrawnSharedPropertyLocation$WithoutErrorBoundary(props) {
  var sharedProperty = use(props.sharedProperty);
  var s = sharedProperty.propertyLocation;
  var match;
  switch (s.__typename) {
    case "PropertyLocationWithHiddenAddress" :
        match = [
          s.locationDisplay,
          undefined
        ];
        break;
    case "PropertyLocationWithVisibleAddress" :
        match = [
          s.locationDisplay,
          s.address
        ];
        break;
    case "__unselected" :
        match = UnselectedUnionMemberError.raise(s._0);
        break;
    
  }
  var address = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: match[0],
                      className: "text-gray-700 font-medium text-lg sm:text-xl"
                    }),
                address !== undefined ? JsxRuntime.jsx("div", {
                        children: address,
                        className: "text-gray-500 text-lg"
                      }) : null
              ],
              className: "space-y-sm sm:space-y-0 flex-col lg:flex-row items-baseline"
            });
}

var WithoutErrorBoundary = {
  make: WithdrawnSharedPropertyLocation$WithoutErrorBoundary
};

function WithdrawnSharedPropertyLocation(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "location",
              children: JsxRuntime.jsx(WithdrawnSharedPropertyLocation$WithoutErrorBoundary, {
                    sharedProperty: props.sharedProperty
                  })
            });
}

var make = WithdrawnSharedPropertyLocation;

exports.SharedPropertyFragment = SharedPropertyFragment;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* react/jsx-runtime Not a pure module */
