// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Loader = require("../../uikit/reason/atoms/Loader/Loader.bs.js");
var Sentry = require("../../reason/Sentry.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var MarkupHelpers = require("../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var ErrorComponent = require("../../reason/common/Error/ErrorComponent.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RelaySharedProperty = require("./RelaySharedProperty.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var ReactErrorBoundary = require("react-error-boundary");
var RemovedSharedPropertyV5 = require("./RemovedSharedPropertyV5.bs.js");
var Sharing__ClientPageHeader = require("../../reason/sharing/components/Sharing__ClientPageHeader/Sharing__ClientPageHeader.bs.js");
var UnselectedUnionMemberError = require("../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var TerminatedAgentSharedPropertyV5 = require("./TerminatedAgentSharedPropertyV5.bs.js");
var RelaySharedPropertyPageQuery_graphql = require("../../__generated__/RelaySharedPropertyPageQuery_graphql.bs.js");

var convertVariables = RelaySharedPropertyPageQuery_graphql.Internal.convertVariables;

var convertResponse = RelaySharedPropertyPageQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = RelaySharedPropertyPageQuery_graphql.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, RelaySharedPropertyPageQuery_graphql.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, RelaySharedPropertyPageQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(RelaySharedPropertyPageQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(RelaySharedPropertyPageQuery_graphql.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(RelaySharedPropertyPageQuery_graphql.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(RelaySharedPropertyPageQuery_graphql.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var defaultMessage = "There was a problem loading the property. Please refresh or try again later...";

function RelaySharedPropertyPage$ErrorState(props) {
  var __message = props.message;
  var message = __message !== undefined ? __message : defaultMessage;
  return JsxRuntime.jsx(MarkupHelpers.Container.make, {
              children: JsxRuntime.jsx(ErrorComponent.make, {
                    message: message
                  })
            });
}

var ErrorState = {
  defaultMessage: defaultMessage,
  make: RelaySharedPropertyPage$ErrorState
};

function RelaySharedPropertyPage$Page(props) {
  var match = use({
        id: props.id
      }, undefined, undefined, undefined);
  var sharedProperty = match.sharedPropertyV5;
  switch (sharedProperty.__typename) {
    case "NotFoundError" :
        return JsxRuntime.jsx(RelaySharedPropertyPage$ErrorState, {
                    message: "The link doesn't appear to be valid. Please contact support"
                  });
    case "RemovedSharedPropertyError" :
        return JsxRuntime.jsx(RemovedSharedPropertyV5.make, {
                    removedSharedProperty: sharedProperty.fragmentRefs
                  });
    case "SharedPropertyV5" :
        return JsxRuntime.jsx(RelaySharedProperty.make, {
                    sharedProperty: sharedProperty.fragmentRefs
                  });
    case "TerminatedSharingAgentError" :
        return JsxRuntime.jsx(TerminatedAgentSharedPropertyV5.make, {
                    terminatedAgentSharedProperty: sharedProperty.fragmentRefs
                  });
    case "__unselected" :
        return UnselectedUnionMemberError.raise("Unselected union member: " + sharedProperty._0 + " in SharedProperty query");
    
  }
}

var Page = {
  make: RelaySharedPropertyPage$Page
};

function RelaySharedPropertyPage$LoadingState(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Loader.make, {}),
              className: "items-center justify-center flex grow"
            });
}

var LoadingState = {
  make: RelaySharedPropertyPage$LoadingState
};

function RelaySharedPropertyPage$default(props) {
  var id = props.id;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Sharing__ClientPageHeader.make, {}),
                JsxRuntime.jsx(ReactErrorBoundary.ErrorBoundary, {
                      FallbackComponent: (function (_error) {
                          return JsxRuntime.jsx(RelaySharedPropertyPage$ErrorState, {});
                        }),
                      children: JsxRuntime.jsx(React.Suspense, {
                            children: Caml_option.some(id !== undefined ? JsxRuntime.jsx(RelaySharedPropertyPage$Page, {
                                        id: id
                                      }) : JsxRuntime.jsx(RelaySharedPropertyPage$LoadingState, {})),
                            fallback: Caml_option.some(JsxRuntime.jsx(RelaySharedPropertyPage$LoadingState, {}))
                          }),
                      onError: (function (error) {
                          Sentry.captureException(error, "RelaySharedPropertyPage.res", undefined, undefined, undefined);
                        })
                    })
              ],
              className: "flex flex-col grow"
            });
}

var Container;

var $$default = RelaySharedPropertyPage$default;

exports.Container = Container;
exports.Query = Query;
exports.ErrorState = ErrorState;
exports.Page = Page;
exports.LoadingState = LoadingState;
exports.default = $$default;
exports.__esModule = true;
/* use Not a pure module */
