// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment_propertyLocation(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "PropertyLocationWithHiddenAddress",
              "PropertyLocationWithVisibleAddress"
            ]);
}

var fragmentConverter = {"__root":{"propertyLocation":{"u":"fragment_propertyLocation"}}};

var fragmentConverterMap = {
  fragment_propertyLocation: unwrap_fragment_propertyLocation
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "kind": "RequiredField",
  "field": {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locationDisplay",
    "storageKey": null
  },
  "action": "THROW"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithdrawnSharedPropertyLocation_sharedProperty",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "propertyLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "PropertyLocationWithHiddenAddress",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                "action": "THROW"
              }
            ],
            "type": "PropertyLocationWithVisibleAddress",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "SharedPropertyV5",
  "abstractKey": null
};
})());

var wrap_fragment_propertyLocation = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment_propertyLocation = unwrap_fragment_propertyLocation;
exports.wrap_fragment_propertyLocation = wrap_fragment_propertyLocation;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
